var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter un client"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de client ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nom entreprise / client"}},[_c('validation-provider',{attrs:{"name":"nom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.client.company_name),callback:function ($$v) {_vm.$set(_vm.client, "company_name", $$v)},expression:"client.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Email","state":errors.length > 0 ? false : null},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"Type","options":_vm.types,"placeholder":"Type"},model:{value:(_vm.client.type),callback:function ($$v) {_vm.$set(_vm.client, "type", $$v)},expression:"client.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Numéro mobile"}},[_c('validation-provider',{attrs:{"name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Mobile"},model:{value:(_vm.client.mobile),callback:function ($$v) {_vm.$set(_vm.client, "mobile", $$v)},expression:"client.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Numéro fix"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Numéro fix"},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"website"}},[_c('validation-provider',{attrs:{"name":"website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Site web"},model:{value:(_vm.client.website),callback:function ($$v) {_vm.$set(_vm.client, "website", $$v)},expression:"client.website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fax"}},[_c('validation-provider',{attrs:{"name":"fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Fax"},model:{value:(_vm.client.fax),callback:function ($$v) {_vm.$set(_vm.client, "fax", $$v)},expression:"client.fax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse"}},[_c('validation-provider',{attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"adresse"},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"MF/ CIN"}},[_c('validation-provider',{attrs:{"name":"fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"MF/ CIN"},model:{value:(_vm.client.tax_identification_number),callback:function ($$v) {_vm.$set(_vm.client, "tax_identification_number", $$v)},expression:"client.tax_identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addClient.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }